import documentLines, { ILedgerDocumentLinesState } from '@/pages/backoffice/Ledger/LedgerDocumentLines/reducer';
import document, { ILedgerDocumentState } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/reducer';
import approveDocumentModal, { IApproveDocumentList } from '@/pages/backoffice/Modals/ApproveDocumentModal/reducer';
import { combineReducers } from 'redux';
import filters, { ILedgerFiltersState } from './LedgerFilters/reducer';
import list, { ILedgerListState } from './LedgerList/reducer';

export interface ILedgerState {
  filters: ILedgerFiltersState;
  list: ILedgerListState;
  document: ILedgerDocumentState;
  documentLines: ILedgerDocumentLinesState;
  approveDocumentModal: IApproveDocumentList;
}

export default combineReducers({
  filters,
  list,
  document,
  documentLines,
  approveDocumentModal,
});
