import declineDocumentSagas from '@/pages/backoffice/Modals/DeclineDocumentModal/sagas';
import approveDocumentSagas from '@/pages/backoffice/Modals/ApproveDocumentModal/sagas';
import createEditContractSagas from './CreateEditContractModal/sagas';
import createEditWalletSagas from './CreateEditWalletModal/sagas';
import sendForApprovalDocumentSagas from './SendForApprovalModal/sagas';
import sendToErpDocumentSagas from './SendToErpModal/sagas';
import declineRequestSagas from './DeclineRequestModal/sagas';

export default [
  ...createEditWalletSagas,
  ...createEditContractSagas,
  ...sendToErpDocumentSagas,
  ...sendForApprovalDocumentSagas,
  ...declineDocumentSagas,
  ...approveDocumentSagas,
  ...declineRequestSagas,
];
