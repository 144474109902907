import { ApproveStatusEnum, UpdateApprove } from '@/generated';
import { getParsedQuery } from '@core-ui/url';
import { PUT } from 'api/oceanApi';
import { responseError } from 'app/sagas';
import { ISagaContext } from 'app/types/common';
import { showNotification } from 'app/actions';
import isNil from 'lodash-es/isNil';
import { BACKOFFICE_TABS } from 'pages/backoffice/consts';
import {
  getMasterDocument,
  setMasterDocumentLoading,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/actions';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import { getSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IApproveDeclineDocument } from './actions';

function* approveDocument({ payload }: Action<IApproveDeclineDocument>) {
  try {
    const { documentId } = payload;

    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PUT, '/approves', [
      {
        document_id: documentId,
        status: ApproveStatusEnum.APPROVED,
      },
    ] as UpdateApprove[]);

    const history: ISagaContext['history'] = yield getContext('history');
    const isRequestsPage = history.location.pathname.includes(BACKOFFICE_TABS.REQUESTS);
    const query = getParsedQuery<IRequestsQueryParams>();
    if (isRequestsPage && !isNil(query.selectedRequestId)) {
      yield put(getSingleRequest({ id: query.selectedRequestId }));
    }

    yield all([
      put(getMasterDocument({ documentId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.document_approved',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setMasterDocumentLoading({ loading: false }));
  }
}

function* declineDocument({ payload }: Action<IApproveDeclineDocument>) {
  try {
    const { documentId } = payload;

    yield put(setMasterDocumentLoading({ loading: true }));

    yield call(PUT, '/approves', [
      {
        document_id: documentId,
        status: ApproveStatusEnum.DECLINED,
      },
    ] as UpdateApprove[]);

    const history: ISagaContext['history'] = yield getContext('history');
    const isRequestsPage = history.location.pathname.includes(BACKOFFICE_TABS.REQUESTS);
    const query = getParsedQuery<IRequestsQueryParams>();
    if (isRequestsPage && !isNil(query.selectedRequestId)) {
      yield put(getSingleRequest({ id: query.selectedRequestId }));
    }

    yield all([
      put(getMasterDocument({ documentId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.document_declined',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setMasterDocumentLoading({ loading: false }));
  }
}

export default [
  takeLatest(actions.approveDocument, approveDocument),
  takeLatest(actions.declineDocument, declineDocument),
];
