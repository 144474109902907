import { DocTypePublicNameEnum } from '@/generated';
import history from '@/history';
import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import { buildURL } from '@core-ui/url';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowSquareOut, FileText, Info, Receipt, X } from '@phosphor-icons/react';
import { BACKEND_DATE_STRING_FORMAT, DATE_FORMAT } from 'app/consts/app';
import { IRouteParams, ROUTES } from 'app/consts/routes';
import { formatTime, parseISO } from 'app/utils/dates';
import { FormattedMessage } from 'components/Intl';
import StatusBadge from 'components/StatusBadge';
import { StatusValues } from 'components/StatusBadge/StatusBadge';
import { useAppSelector } from 'hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'pages/backoffice/consts';
import { closeBackofficeModal } from 'pages/backoffice/Modals/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  MASTER_DOCUMENT_INFO_TAB_DIVIDER_WIDTH,
  MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH,
  MASTER_DOCUMENT_INFO_TAB_SELECTORS_WIDTH,
  MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH,
  MASTER_DOCUMENT_PAYMENT_DATE_TOTAL_AMOUNT_WIDTH,
} from '../consts';
import {
  deleteErpCategory,
  deleteRequest,
  deleteWallet,
  updateErpCategory,
  updatePaymentDate,
  updatePaymentInfoTotal,
  updateRequest,
  updateVisibilityInAnalytics,
  updateWallet,
} from './actions';
import selector from './selector';
import useStyles from './styles';
import DatePicker from '@core-ui/datepicker';
import { Nullable } from '@core-ui/types';
import isNil from 'lodash-es/isNil';
import NumberField from 'components/NumberField';
import { LEGACY_DETAIL_REQUEST_TABS } from 'pages/backoffice/Requests/consts';

const MasterDocumentModalInfo = () => {
  const {
    document,
    requestOptions,
    loading,
    clientCostArticlesOptions,
    walletOptions,
    boatId,
    paymentInfoCurrencyId,
    paymentInfoCurrencyCode,
    paymentInfoDate,
    paymentInfoAmountInitial,
    paymentInfoRate,
  } = useAppSelector(selector);

  const [paymentInfoAmount, setPaymentInfoAmount] = useState<string>(paymentInfoAmountInitial);

  const dispatch = useDispatch();
  const styles = useStyles({ loading });
  const { backofficeTab } = useParams<IRouteParams>();

  const handleErpChange = (value: number) => {
    if (document) {
      dispatch(updateErpCategory({ documentId: document.id, categoryId: String(value) }));
    }
  };

  const handleWalletChange = (value: number) => {
    if (document) {
      dispatch(updateWallet({ documentId: document.id, walletId: value }));
    }
  };

  const handleRequestChange = (value: number) => {
    if (document) {
      dispatch(updateRequest({ documentId: document.id, requestId: value }));
    }
  };

  const handleRedirectToWallet = () => {
    if (document) {
      dispatch(closeBackofficeModal());
      history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}/${document.wallet_id}`));
    }
  };

  const handleRedirectToRequest = () => {
    if (document) {
      dispatch(closeBackofficeModal());

      // TODO: заменить на роут до новой панели просмотра реквеста
      // ! шо?
      history.push(
        buildURL(
          `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${document.request_id}/${LEGACY_DETAIL_REQUEST_TABS.ABOUT}`
        )
      );
    }
  };

  const handleChangeVisibilityInAnalytics = () => {
    if (document) {
      dispatch(
        updateVisibilityInAnalytics({
          documentId: document.id,
          hide: !document.is_hide_from_analytics,
        })
      );
    }
  };

  const handleDeleteErpCategory = () => {
    if (document) {
      dispatch(deleteErpCategory({ documentId: document.id }));
    }
  };

  const handleDeleteWallet = () => {
    if (document) {
      dispatch(deleteWallet({ documentId: document.id }));
    }
  };

  const handleDeleteRequest = () => {
    if (document) {
      dispatch(deleteRequest({ documentId: document.id }));
    }
  };

  const handlePaymentDateChange = (newDate: Nullable<Date>) => {
    if (isNil(newDate) || isNil(document)) {
      return;
    }

    const formattedDate = formatTime(newDate, { dateFormat: BACKEND_DATE_STRING_FORMAT });

    dispatch(
      updatePaymentDate({
        documentId: document.id,
        paymentDate: formattedDate,
      })
    );
  };

  const handleTotalAmountChange = (newAmount: Nullable<string>) => {
    setPaymentInfoAmount(newAmount ?? '');
  };

  const handleSubmitTotalAmount = () => {
    if (isNil(document) || isNil(paymentInfoCurrencyId) || paymentInfoAmount === paymentInfoAmountInitial) {
      return;
    }

    dispatch(
      updatePaymentInfoTotal({
        documentId: document.id,
        currencyId: paymentInfoCurrencyId,
        total: Number(paymentInfoAmount),
      })
    );
  };

  const handleTotalAmountKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSubmitTotalAmount();
    }
  };

  const isDocumentReceipt = document?.document_type === DocTypePublicNameEnum.RECEIPT;

  let documentTypeIcon = <FormattedMessage id="label.no_info" />;
  if (document) {
    documentTypeIcon = isDocumentReceipt ? (
      <>
        <SvgIcon>
          <Receipt />
        </SvgIcon>

        <Typography>
          <FormattedMessage id="document_type.receipt" />
        </Typography>
      </>
    ) : (
      <>
        <SvgIcon>
          <FileText />
        </SvgIcon>

        <Typography>
          <FormattedMessage id="document_type.invoice" />
        </Typography>
      </>
    );
  }

  const location = document?.location ?? <FormattedMessage id="label.no_info" />;

  // TODO: на бэке дикое непостоянство с датами, форматы где какие. Нужно чтобы их все привели к unix timestamp
  // issue_date приходит в виде ISO строки, а created_at в виде форматированной строки с точками вместо слешей
  const issueDate = document?.issue_date ? (
    formatTime(parseISO(document.issue_date), { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const createdAt = document?.created_at ? (
    document.created_at.replace(/\./g, '/')
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  // TODO: удалить каст когда бэк добавит типизацию параметра status схемы DocumentSearchResult
  const statusBadge = document?.status ? (
    <StatusBadge status={document.status as StatusValues} />
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const uploadedByName = [document?.created_by_first_name, document?.created_by_last_name]
    .filter(Boolean)
    .join(' ') || <FormattedMessage id="label.no_info" />;

  const isWalletsPage = backofficeTab === BACKOFFICE_TABS.WALLETS;
  const isRequestsPage = backofficeTab === BACKOFFICE_TABS.REQUESTS;

  return (
    <Grid container direction="column" gap={3} paddingBottom={16} wrap="nowrap">
      <Grid item container wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.document_type" />
          </Typography>
        </Grid>

        <Grid item container gap={1} width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
          {documentTypeIcon}
        </Grid>
      </Grid>

      {!isDocumentReceipt && (
        <Grid item container wrap="nowrap">
          <Grid item sx={styles.rowText}>
            <Typography>
              <FormattedMessage id="label.status" />
            </Typography>
          </Grid>

          <Grid item width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
            {statusBadge}
          </Grid>
        </Grid>
      )}

      <Grid item container wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.location" />
          </Typography>
        </Grid>

        <Grid item width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
          {location}
        </Grid>
      </Grid>

      <Grid item container wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.issue_date" />
          </Typography>
        </Grid>

        <Grid item width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
          {issueDate}
        </Grid>
      </Grid>

      <Grid item container wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.description" />
          </Typography>
        </Grid>

        <Grid item width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
          {/* todo: такого поля ещё нет на бэке */}
          {/* {document?.description} */}
          <FormattedMessage id="label.no_info" />
        </Grid>
      </Grid>

      <Grid item marginTop={2} width={MASTER_DOCUMENT_INFO_TAB_DIVIDER_WIDTH}>
        <Divider />
      </Grid>

      <Grid item container marginTop={2} wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.payment_info" />
          </Typography>
        </Grid>

        <Grid item container direction="column" gap={3} width={MASTER_DOCUMENT_INFO_TAB_SELECTORS_WIDTH} wrap="nowrap">
          <Grid item container>
            <Grid item container direction="column" gap={3} width={MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
              <DatePicker
                size="medium"
                disabled={loading}
                dateTimeFormat={DATE_FORMAT}
                openOnFocus
                label={<FormattedMessage id="label.payment_date" />}
                onChange={handlePaymentDateChange}
                value={paymentInfoDate ? parseISO(paymentInfoDate) : null}
              />

              <Grid item width={MASTER_DOCUMENT_PAYMENT_DATE_TOTAL_AMOUNT_WIDTH}>
                <NumberField
                  fullWidth
                  rightIcon1={<Typography color={COLOURS.Coconut.Solid[900]}>{paymentInfoCurrencyCode}</Typography>}
                  label={<FormattedMessage id="label.total_amount" />}
                  value={paymentInfoAmount}
                  onChange={handleTotalAmountChange}
                  onBlur={handleSubmitTotalAmount}
                  onKeyDown={handleTotalAmountKeyDown}
                />
              </Grid>

              <Grid item container gap={1} wrap="nowrap">
                <Typography variant="body2" component="span">
                  <FormattedMessage id="label.exchange_rate" />
                </Typography>

                <Typography variant="body2" fontWeight="semiBold" component="span">
                  {paymentInfoRate ?? '--'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item marginTop={2} width={MASTER_DOCUMENT_INFO_TAB_DIVIDER_WIDTH}>
        <Divider />
      </Grid>

      <Grid item container marginTop={2} wrap="nowrap">
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.relations" />
          </Typography>

          <Typography color={COLOURS.Coconut.Solid[600]} fontSize={12}>
            <FormattedMessage id="label.document_settings" />
          </Typography>
        </Grid>

        <Grid item container direction="column" gap={3} width={MASTER_DOCUMENT_INFO_TAB_SELECTORS_WIDTH} wrap="nowrap">
          <Grid item container gap={2}>
            <Grid item width={MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
              <Autocomplete
                label={<FormattedMessage id="label.erp_category" />}
                loading={loading}
                disabled={loading || !document}
                value={document?.erp_category}
                onChange={handleErpChange}
                options={clientCostArticlesOptions}
              />
            </Grid>

            {document?.erp_category && (
              <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                <Tooltip
                  title={<FormattedMessage id="label.delete_erp_category_from_document" />}
                  placement="top"
                  arrow
                >
                  <Box>
                    <Button
                      compact
                      variant="text"
                      color="blue"
                      size="small"
                      disabled={loading || !document}
                      onClick={handleDeleteErpCategory}
                      icon={
                        <SvgIcon htmlColor={loading || !document ? undefined : COLOURS.Strawberry.Solid[600]}>
                          <X />
                        </SvgIcon>
                      }
                    />
                  </Box>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item container gap={2} wrap="nowrap">
            <Grid item width={MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
              <Autocomplete
                label={<FormattedMessage id="label.wallet" />}
                loading={loading}
                disabled={loading || !document}
                value={document?.wallet_id}
                onChange={handleWalletChange}
                options={walletOptions}
              />
            </Grid>

            {document?.wallet_id && (
              <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                <Tooltip title={<FormattedMessage id="label.delete_document_from_wallet" />} placement="top" arrow>
                  <Box>
                    <Button
                      compact
                      variant="text"
                      color="blue"
                      size="small"
                      disabled={loading || !document}
                      onClick={handleDeleteWallet}
                      icon={
                        <SvgIcon htmlColor={loading || !document ? undefined : COLOURS.Strawberry.Solid[600]}>
                          <X />
                        </SvgIcon>
                      }
                    />
                  </Box>
                </Tooltip>

                {!isWalletsPage && (
                  <Tooltip title={<FormattedMessage id="label.open_wallet" />} placement="top" arrow>
                    <Box>
                      <Button
                        compact
                        variant="text"
                        color="blue"
                        size="small"
                        disabled={loading || !document}
                        onClick={handleRedirectToWallet}
                        icon={<ArrowSquareOut />}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item container gap={2} wrap="nowrap">
            <Grid item width={MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH}>
              <Autocomplete
                label={<FormattedMessage id="label.request" />}
                loading={loading}
                disabled={loading || !document}
                value={document?.request_id}
                onChange={handleRequestChange}
                options={requestOptions}
              />
            </Grid>

            {document?.request_id && (
              <Grid item container width="auto" alignItems="center" gap={2} wrap="nowrap">
                <Tooltip title={<FormattedMessage id="label.delete_document_from_request" />} placement="top" arrow>
                  <Box>
                    <Button
                      compact
                      variant="text"
                      color="blue"
                      size="small"
                      disabled={loading || !document}
                      onClick={handleDeleteRequest}
                      icon={
                        <SvgIcon htmlColor={loading || !document ? undefined : COLOURS.Strawberry.Solid[600]}>
                          <X />
                        </SvgIcon>
                      }
                    />
                  </Box>
                </Tooltip>

                {!isRequestsPage && (
                  <Tooltip title={<FormattedMessage id="label.open_request" />} placement="top" arrow>
                    <Box>
                      <Button
                        compact
                        variant="text"
                        color="blue"
                        size="small"
                        disabled={loading || !document}
                        onClick={handleRedirectToRequest}
                        icon={<ArrowSquareOut />}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item width="auto">
            <Divider />
          </Grid>

          <Grid item container gap={2} wrap="nowrap">
            <Grid
              item
              container
              gap={2}
              width="auto"
              sx={styles.visibilityInAnalyticsContainer}
              onClick={handleChangeVisibilityInAnalytics}
              wrap="nowrap"
            >
              <Switch size="medium" disabled={loading || !document} checked={!document?.is_hide_from_analytics} />

              <Grid item>
                <FormattedMessage id="label.count_document_in_analytics" />
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip title={<FormattedMessage id="tooltip.count_document_in_analytics" />} placement="top" arrow>
                <SvgIcon>
                  <Info />
                </SvgIcon>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item marginTop={2} width={MASTER_DOCUMENT_INFO_TAB_DIVIDER_WIDTH}>
        <Divider />
      </Grid>

      <Grid item container wrap="nowrap" marginTop={2}>
        <Grid item sx={styles.rowText}>
          <Typography>
            <FormattedMessage id="label.uploaded_by" />
          </Typography>
        </Grid>

        <Grid item width="auto" maxWidth={MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH}>
          {uploadedByName}

          <Typography color={COLOURS.Coconut.Solid[600]}>{createdAt}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MasterDocumentModalInfo;
