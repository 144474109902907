import { getParams } from '@core-ui/redux-router';
import { POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { ISagaContext } from '@/app/types/common';
import { DocumentSearchResult } from '@/generated';
import { LEDGER_PATHS } from '@/pages/backoffice/Ledger/routes';
import { LedgerDocumentPathParams } from '@/pages/backoffice/Ledger/types';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getLedgerDocument() {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const { documentId } = getParams<LedgerDocumentPathParams>(history.location.pathname, LEDGER_PATHS);

    const boatId: string = yield select(getCurrentBoatId);

    const documentData: DocumentSearchResult[] = yield call(POST, '/document/get_by_filter', {
      id: Number(documentId),
      boat_id: boatId,
    });

    if (!documentData[0]) {
      throw new Error('Document not found');
    }

    yield put(
      actions.setLedgerDocument({
        value: documentData[0],
        hasData: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setLedgerDocument({
        error: e as Error,
        hasData: false,
      })
    );
  }
}

export default [takeLatest(actions.getLedgerDocument, getLedgerDocument)];
