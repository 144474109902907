import { ApproveStatusEnum, DocumentSearchResult } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { DeclineDocumentModalData } from '@/pages/backoffice/Modals/command';
import ApproveDocumentModalData from '@/pages/backoffice/Modals/command/ApproveDocumentModalData';
import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Check, Prohibit } from '@phosphor-icons/react';
import { FormattedMessage, useIntl } from 'components/Intl';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  item: DocumentSearchResult;
}

const ValidationRowButtons = (props: IProps) => {
  const { item } = props;

  const isApproveButtonsDisabled = item.status !== ApproveStatusEnum.AWAITING;

  const styles = useStyles({ status: item.status });
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleOpenApproveModal = () => {
    dispatch(openBackofficeModal(new ApproveDocumentModalData(item.id, PARENT_PAGE.LEDGER_LIST)));
  };

  const handleOpenDeclineModal = () => {
    dispatch(openBackofficeModal(new DeclineDocumentModalData(item.id, PARENT_PAGE.LEDGER_LIST)));
  };

  return (
    <Grid item container justifyContent="flex-end" wrap="nowrap" gap={2}>
      <Tooltip
        title={
          <FormattedMessage
            id={
              isApproveButtonsDisabled
                ? 'tooltip.document_has_already_been_approved_or_declined'
                : 'label.approve_document'
            }
            values={{ status: item.status }}
          />
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleOpenApproveModal}
            sx={styles.approveButton}
            aria-label={intl.formatMessage({ id: 'aria.label.approve_document' })}
            icon={
              <Check
                color={
                  item.status === ApproveStatusEnum.DECLINED ? COLOURS.Coconut.Solid[500] : COLOURS.Avocado.Solid[600]
                }
              />
            }
          />
        </div>
      </Tooltip>

      <Tooltip
        title={
          <FormattedMessage
            id={
              isApproveButtonsDisabled
                ? 'tooltip.document_has_already_been_approved_or_declined'
                : 'label.decline_document'
            }
            values={{ status: item.status }}
          />
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleOpenDeclineModal}
            sx={styles.declineButton}
            aria-label={intl.formatMessage({ id: 'aria.label.decline_document' })}
            icon={
              <Prohibit
                color={
                  item.status === ApproveStatusEnum.APPROVED
                    ? COLOURS.Coconut.Solid[500]
                    : COLOURS.Strawberry.Solid[600]
                }
              />
            }
          />
        </div>
      </Tooltip>
    </Grid>
  );
};

export default ValidationRowButtons;
