import { createSelector } from 'reselect';
import { getBudgetApproveFlowEnabled, getLevelUserApprovalEnabled, getCurrentBoatId } from '@/app/selectors';
import { getAmountFormatter, getCurrencyCodeById, getRequestsDictOptions } from '@/dictionary/selector';
import { getDocumentsItems } from '@/pages/backoffice/Ledger/LedgerList/selector';

export default createSelector(
  [
    getDocumentsItems,
    getCurrencyCodeById,
    getAmountFormatter,
    getBudgetApproveFlowEnabled,
    getLevelUserApprovalEnabled,
    getCurrentBoatId,
    getRequestsDictOptions,
  ],
  (
    items,
    getCurrencyCode,
    amountFormatter,
    budgetApproveFlowActive,
    levelUserApprovalEnabled,
    boatId,
    requestsOptions
  ) => ({
    items,
    getCurrencyCode,
    amountFormatter,
    budgetApproveFlowActive: budgetApproveFlowActive ?? false,
    levelUserApprovalEnabled: levelUserApprovalEnabled ?? false,
    boatId,
    requestsOptions,
  })
);
