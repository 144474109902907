import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { generateEmptyArray } from '@/app/utils/app';
import { SINGLE_DOCUMENT_INFO_TAB_DIVIDER_WIDTH } from '@/pages/backoffice/Ledger/consts';
import React from 'react';
import { useIntl } from 'components/Intl';

const LedgerDocumentInfoSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      item
      container
      direction="column"
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Grid container direction="column" gap={4} maxWidth={350}>
        {generateEmptyArray(8).map((_, index) => (
          <Skeleton key={index} variant="rounded" height={20} />
        ))}
      </Grid>

      <Grid item marginY={12} width={SINGLE_DOCUMENT_INFO_TAB_DIVIDER_WIDTH}>
        <Divider />
      </Grid>

      <Grid container direction="column" gap={4} maxWidth={620}>
        {generateEmptyArray(5).map((_, index) => (
          <Skeleton key={index} variant="rounded" height={20} />
        ))}
      </Grid>
    </Grid>
  );
};

export default LedgerDocumentInfoSkeleton;
