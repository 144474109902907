import Autocomplete from '@core-ui/autocomplete';
import { TableCell, TableRow } from '@core-ui/table';
import { IOption } from '@core-ui/types';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Note } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { AmountFormatter, CurrencyCodeGetterById } from '@/app/types/common';
import { DocumentLinesResponse } from '@/generated';
import { IUpdateLineErpCategory, updateLineErpCategory } from '@/pages/backoffice/Ledger/LedgerDocumentLines/actions';
import useStyles from './styles';
import { useIntl } from 'components/Intl';

interface IProps {
  item: DocumentLinesResponse;
  amountFormatter: AmountFormatter;
  getCurrencyCode: CurrencyCodeGetterById;
  clientCostArticlesOptions: IOption[];
  documentId: number;
}

const LedgerDocumentLinesRow = (props: IProps) => {
  const { item, amountFormatter, getCurrencyCode, documentId, clientCostArticlesOptions } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleErpChange = (value: number) => {
    dispatch(
      updateLineErpCategory({
        documentId,
        lineId: item.id,
        categoryId: String(value),
      } as IUpdateLineErpCategory)
    );
  };

  const description = item.description?.trim() && (
    <Tooltip title={item.description} placement="top" arrow>
      <SvgIcon>
        <Note />
      </SvgIcon>
    </Tooltip>
  );

  return (
    <TableRow>
      <TableCell sx={styles.lineNameCell}>{item.line_name}</TableCell>

      <TableCell sx={styles.cell}>{item.cost_article}</TableCell>

      <TableCell sx={styles.cell}>{description}</TableCell>

      <TableCell padding="none" sx={styles.autocompleteCell}>
        <Autocomplete
          isDynamic
          hiddenBorder
          fullWidth
          fullHeight
          size="small"
          value={item.xero_code}
          onChange={handleErpChange}
          options={clientCostArticlesOptions}
          dataTestId="ledger-single-document-line-erp-category-autocomplete"
          inputAriaLabel={intl.formatMessage({ id: 'aria.label.erp_category_selector' })}
        />
      </TableCell>

      <TableCell align="center" sx={styles.cell}>
        {item.qty}
      </TableCell>

      <TableCell align="right" sx={styles.cell}>
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.unit_price)}
        </Typography>
      </TableCell>

      <TableCell align="right" sx={styles.cell}>
        {item.vat_percent}
      </TableCell>

      <TableCell align="right" sx={styles.cell}>
        {item.vat}
      </TableCell>

      <TableCell align="center" sticky right={160} shadow="dynamicLeft" sx={styles.cell}>
        {getCurrencyCode(item.total_price_currency_id)}
      </TableCell>

      <TableCell align="right" sticky right={0} sx={styles.cell}>
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default LedgerDocumentLinesRow;
