import { DELETE, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from 'app/actions';
import { DocPatchInputSchema } from '@/generated';
import { getLedgerDocument, setLedgerDocumentLoading } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/actions';
import { IUpdateRequest } from '@/pages/backoffice/Ledger/LedgerList/LedgerListRow/actions';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* updatePaymentDate({ payload }: Action<actions.IUpdatePaymentDate>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      payment_info: {
        payment_date: payload.paymentDate,
      },
    } as DocPatchInputSchema);

    yield all([
      put(getLedgerDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.document_update_payment_date',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* updatePaymentInfoTotal({ payload }: Action<actions.IUpdatePaymentInfoTotal>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      payment_info: {
        total_price: payload.total,
        currency_id: payload.currencyId,
      },
    } as DocPatchInputSchema);

    yield all([
      put(getLedgerDocument({ documentId: payload.documentId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.document_update_payment_amount',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* updateErpCategory({ payload }: Action<actions.IUpdateErpCategory>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      xero_category: payload.categoryId,
    } as DocPatchInputSchema);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.erp_update',
        })
      ),
    ]);
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        title: 'notification.error.text.erp_update',
      })
    );
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* deleteErpCategory({ payload }: Action<actions.IDeleteErpCategory>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/xero_category`);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.erp_delete',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* updateWallet({ payload }: Action<actions.IUpdateWallet>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      wallet_id: payload.walletId,
    } as DocPatchInputSchema);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.wallet_update',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* deleteWallet({ payload }: Action<actions.IDeleteWallet>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/wallet`);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.wallet_delete',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* updateRequest({ payload }: Action<IUpdateRequest>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      request_id: payload.requestId,
    } as DocPatchInputSchema);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.request_update',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* deleteRequest({ payload }: Action<actions.IDeleteRequest>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(DELETE, `/document/${payload.documentId}/request`);

    yield all([
      put(getLedgerDocument(null)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.request_delete',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

function* updateVisibilityInAnalytics({ payload }: Action<actions.IUpdateVisibilityInAnalytics>) {
  try {
    yield put(setLedgerDocumentLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      hide_from_analytics: payload.hide,
    } as DocPatchInputSchema);

    yield put(getLedgerDocument(null));

    yield put(
      showNotification({
        variant: 'success',
        title: 'notification.success.text.visibility_in_analytics_update',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerDocumentLoading({ loading: false }));
  }
}

export default [
  takeLatest(actions.updatePaymentDate, updatePaymentDate),
  takeLatest(actions.updatePaymentInfoTotal, updatePaymentInfoTotal),
  takeLatest(actions.updateErpCategory, updateErpCategory),
  takeLatest(actions.deleteErpCategory, deleteErpCategory),
  takeLatest(actions.updateWallet, updateWallet),
  takeLatest(actions.deleteWallet, deleteWallet),
  takeLatest(actions.updateRequest, updateRequest),
  takeLatest(actions.deleteRequest, deleteRequest),
  takeLatest(actions.updateVisibilityInAnalytics, updateVisibilityInAnalytics),
];
