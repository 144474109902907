import { getCurrentBoatConfig, getCurrentBoatId } from '@/app/selectors';
import State from '@/app/types/state';
import { getClientCostArticlesDictOptions, getCurrencyCodeById, getRequestsDictOptions } from '@/dictionary/selector';
import { getLedgerDocumentValue } from '@/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { getWalletsOptions } from '@/pages/backoffice/Wallets/WalletsList/selector';
import { createSelector } from 'reselect';

const getLoading = (state: State) => state.backoffice.ledger.document.loading;

export default createSelector(
  [
    getLedgerDocumentValue,
    getCurrentBoatId,
    getClientCostArticlesDictOptions,
    getWalletsOptions,
    getRequestsDictOptions,
    getLoading,
    getCurrencyCodeById,
    getCurrentBoatConfig,
  ],
  (
    document,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,
    currencyCodeGetter,
    boatConfig
  ) => ({
    document,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,

    paymentInfoCurrencyCode: currencyCodeGetter(document?.payment_info?.currency_id ?? boatConfig?.default_currency_id),
    paymentInfoCurrencyId: document?.payment_info?.currency_id ?? boatConfig?.default_currency_id,
    paymentInfoDateInitial: document?.payment_date ?? '',
    paymentInfoAmountInitial: document?.payment_info?.total_price ? String(document?.payment_info?.total_price) : '',
    paymentInfoRate: document?.payment_info?.rate ?? null,
  })
);
