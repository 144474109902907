import { DocumentSearchRequest, DocumentSearchResult } from '@/generated';
import { POST } from 'api/oceanApi';
import { responseError } from 'app/sagas';
import { getCurrentBoatId } from 'app/selectors';
import isNil from 'lodash-es/isNil';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IGetMasterDocument } from './actions';

function* getMasterDocument({ payload }: Action<IGetMasterDocument>) {
  try {
    const { documentId } = payload;
    const boatId: string = yield select(getCurrentBoatId);

    if (isNil(documentId)) {
      throw new Error('Document id undefined');
    }

    const body: DocumentSearchRequest = {
      id: Number(documentId),
      boat_id: Number(boatId),
    };

    const documentsList: DocumentSearchResult[] = yield call(POST, '/document/get_by_filter', body);

    if (!documentsList[0]) {
      throw new Error('Document not found');
    }

    yield put(
      actions.setMasterDocument({
        value: documentsList[0],
        hasData: true,
      })
    );
  } catch (e) {
    yield all([
      put(
        actions.setMasterDocument({
          error: e as Error,
          hasData: false,
        })
      ),
      call(responseError, e),
    ]);
  }
}

export default [takeLatest(actions.getMasterDocument, getMasterDocument)];
