import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { useIntl } from 'components/Intl';

const cancelButtonStyles: SxProps<Theme> = {
  background: COLOURS.Coconut.Solid[100],
};

const approveButtonStyles: SxProps<Theme> = {
  background: COLOURS.Fig.Solid[600],
};

const ApproveDocumentModalButtonsSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      justifyContent="flex-end"
      gap={3}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton width={82} height={48} variant="rounded" sx={cancelButtonStyles} />
      <Skeleton width={113} height={48} variant="rounded" sx={approveButtonStyles} />
    </Grid>
  );
};

export default ApproveDocumentModalButtonsSkeleton;
