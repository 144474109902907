import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { declineRequest } from 'pages/backoffice/Modals/DeclineRequestModal/actions';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const DeclineRequestModal = (props: IProps) => {
  const { opened, onClose } = props;

  const dispatch = useDispatch();
  const { requestId, loading } = useAppSelector(selector);

  const handleConfirm = () => {
    dispatch(declineRequest({ requestId }));
  };

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.decline_request.title" />}
    >
      <DialogContent>
        <Grid item container direction="column" gap={4} paddingBottom={3} wrap="nowrap">
          <Typography fontWeight="semiBold">
            <FormattedMessage id="modal.backoffice.decline_request.subtitle" />
          </Typography>

          <Typography>
            <FormattedMessage id="modal.backoffice.decline_request.text" />
          </Typography>
        </Grid>

        <DialogActions>
          <Button
            color="blue"
            size="small"
            autoFocus
            fullWidth
            variant="contained"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
            label={<FormattedMessage id="label.confirm_decline" />}
          />
        </DialogActions>
      </DialogContent>
    </Modal>
  );
};

export default DeclineRequestModal;
