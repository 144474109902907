import { ApproveStatusEnum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { buttonBaseClasses, buttonClasses } from '@mui/material';

export default (props: { status: string | undefined }): Record<string, SxProps<Theme>> => ({
  approveButton: {
    [`&.${buttonBaseClasses.root}.${buttonClasses.outlined}`]: {
      minWidth: 'auto',
      borderRadius: '16px',
      cursor: `${props.status === ApproveStatusEnum.AWAITING ? 'pointer' : 'default'}`,

      color: `${props.status === ApproveStatusEnum.DECLINED ? COLOURS.Coconut.Solid[500] : COLOURS.Avocado.Solid[600]}`,

      background: `${
        props.status === ApproveStatusEnum.DECLINED
          ? COLOURS.Coconut.Solid[200]
          : props.status === ApproveStatusEnum.APPROVED
          ? COLOURS.Avocado.Solid[100]
          : COLOURS.WHITE
      }`,

      borderColor: `${
        props.status === ApproveStatusEnum.DECLINED
          ? COLOURS.Coconut.Solid[200]
          : props.status === ApproveStatusEnum.APPROVED
          ? COLOURS.Avocado.Solid[600]
          : COLOURS.Coconut.Solid[200]
      }`,

      '&:hover': {
        color: `${
          props.status === ApproveStatusEnum.DECLINED
            ? COLOURS.Coconut.Solid[500]
            : props.status === ApproveStatusEnum.APPROVED
            ? COLOURS.Avocado.Solid[600]
            : COLOURS.Avocado.Solid[700]
        }`,

        borderColor: `${
          props.status === ApproveStatusEnum.DECLINED
            ? COLOURS.Coconut.Solid[200]
            : props.status === ApproveStatusEnum.APPROVED
            ? COLOURS.Avocado.Solid[700]
            : COLOURS.Avocado.Solid[600]
        }`,
      },
    },
  },

  declineButton: {
    [`&.${buttonBaseClasses.root}.${buttonClasses.outlined}`]: {
      minWidth: 'auto',
      borderRadius: '16px',
      cursor: `${props.status === ApproveStatusEnum.AWAITING ? 'pointer' : 'default'}`,

      color: `${
        props.status === ApproveStatusEnum.APPROVED ? COLOURS.Coconut.Solid[500] : COLOURS.Strawberry.Solid[600]
      }`,

      background: `${
        props.status === ApproveStatusEnum.APPROVED
          ? COLOURS.Coconut.Solid[200]
          : props.status === ApproveStatusEnum.DECLINED
          ? COLOURS.Strawberry.Solid[100]
          : COLOURS.WHITE
      }`,

      borderColor: `${
        props.status === ApproveStatusEnum.APPROVED
          ? COLOURS.Coconut.Solid[200]
          : props.status === ApproveStatusEnum.DECLINED
          ? COLOURS.Strawberry.Solid[600]
          : COLOURS.Coconut.Solid[200]
      }`,

      '&:hover': {
        color: `${
          props.status === ApproveStatusEnum.APPROVED
            ? COLOURS.Coconut.Solid[500]
            : props.status === ApproveStatusEnum.DECLINED
            ? COLOURS.Strawberry.Solid[600]
            : COLOURS.Strawberry.Solid[700]
        }`,

        borderColor: `${
          props.status === ApproveStatusEnum.APPROVED
            ? COLOURS.Coconut.Solid[200]
            : props.status === ApproveStatusEnum.DECLINED
            ? COLOURS.Strawberry.Solid[600]
            : COLOURS.Strawberry.Solid[700]
        }`,
      },
    },
  },
});
