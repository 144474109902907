import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import TextField from '@core-ui/text_field';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT, RANGE_PICKER_LABELS, TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';
import { dateToDayEndUTC, dateToDayStartUTC, dateToUTC } from '@/app/utils/dates';
import DatePicker, { RangePickerProps } from '@core-ui/datepicker';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setTextFilter } from '@/pages/backoffice/Ledger/LedgerFilters/actions';
import FiltersDrawer from '@/pages/backoffice/Ledger/LedgerFilters/FiltersDrawer';
import { ILedgerFiltersState } from '@/pages/backoffice/Ledger/LedgerFilters/reducer';
import { getLedgerList, setLedgerListLoading } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { Calculator, MagnifyingGlass, UserList } from '@phosphor-icons/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'components/Intl';
import { batch, useDispatch } from 'react-redux';
import selector from './selector';

const switchContainerStyle: SxProps<Theme> = {
  color: COLOURS.Coconut.Solid[700],
  cursor: 'pointer',
  transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

  '&:hover': {
    color: COLOURS.Coconut.Solid[900],
  },
};

const LedgerFilter = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { filters, levelUserApprovalEnabled } = useAppSelector(selector);

  const dispatch = useDispatch();
  const intl = useIntl();

  const setLoadingCallback = useCallback(
    () => dispatch(setLedgerListLoading({ loading: true })),
    [setLedgerListLoading]
  );

  const handleFilterChange = useFilter<ILedgerFiltersState>({
    getList: getLedgerList,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleToggleErpUploadPending = () => {
    handleFilterChange('erpUploadPending')(!filters.erpUploadPending);
  };

  const handleToggleMyDuty = () => {
    handleFilterChange('myDuty')(!filters.myDuty);
  };

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    batch(() => {
      handleFilterChange('dateFrom')(dates?.[0] ? dateToDayStartUTC(dates[0]) : null);
      handleFilterChange('dateTo')(dates?.[1] ? dateToDayEndUTC(dates[1]) : null);
    });
  };

  const handleCloseFiltersDrawer = () => {
    setDrawerOpened(false);
  };

  const handleOpenFiltersDrawer = () => {
    setDrawerOpened(true);
  };

  const pickerValue: [Nullable<Date>, Nullable<Date>] = [null, null];

  if (filters.dateFrom) {
    pickerValue[0] = dateToUTC(+filters.dateFrom);
  }

  if (filters.dateTo) {
    pickerValue[1] = dateToUTC(+filters.dateTo);
  }

  return (
    <>
      <Grid container wrap="nowrap" paddingY={6} gap={3}>
        <Grid item>
          <TextField
            size="small"
            value={filters.name}
            label={<FormattedMessage id="label.search" />}
            // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
            // @ts-ignore
            onChange={handleFilterChange('name')}
            rightIcon1={
              <SvgIcon>
                <MagnifyingGlass />
              </SvgIcon>
            }
            inputProps={{
              'aria-label': intl.formatMessage({ id: 'label.search' }),
            }}
          />
        </Grid>

        <Grid item>
          <DatePicker<RangePickerProps>
            dateTimeFormat={DATE_FORMAT}
            value={pickerValue}
            onChange={handleDateChange}
            label={RANGE_PICKER_LABELS}
            data-testid="ledger-filter-rangepicker"
            size="small"
            view="rangepicker"
            openOnFocus
          />
        </Grid>

        {levelUserApprovalEnabled && (
          <Grid
            item
            container
            gap={2}
            width="auto"
            alignItems="center"
            onClick={handleToggleMyDuty}
            sx={switchContainerStyle}
          >
            <Typography fontSize="small" whiteSpace="nowrap">
              <FormattedMessage id="label.my_duty" />
            </Typography>

            <Grid item container gap={2} width="auto" wrap="nowrap">
              <SvgIcon>
                <UserList />
              </SvgIcon>

              <Switch
                size="medium"
                checked={filters.myDuty}
                inputProps={{
                  'aria-label': intl.formatMessage({ id: 'label.my_duty' }),
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          item
          container
          gap={2}
          alignItems="center"
          width="auto"
          marginLeft={2}
          onClick={handleToggleErpUploadPending}
          sx={switchContainerStyle}
        >
          <Typography fontSize="small" whiteSpace="nowrap">
            <FormattedMessage id="label.erp_upload_pending" />
          </Typography>

          <Grid item container gap={2} width="auto" wrap="nowrap">
            <SvgIcon>
              <Calculator />
            </SvgIcon>

            <Switch
              size="medium"
              checked={filters.erpUploadPending}
              inputProps={{
                'aria-label': intl.formatMessage({ id: 'label.erp_upload_pending' }),
              }}
            />
          </Grid>
        </Grid>

        <Grid item marginLeft={8}>
          <Button
            variant="outlined"
            size="small"
            label={<FormattedMessage id="label.more_filters" />}
            onClick={handleOpenFiltersDrawer}
          />
        </Grid>
      </Grid>

      <FiltersDrawer open={drawerOpened} onClose={handleCloseFiltersDrawer} />
    </>
  );
};

export default LedgerFilter;
