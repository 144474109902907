import { PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from 'app/actions';
import { DocPatchInputSchema } from '@/generated';
import { getLedgerList, setLedgerListLoading } from '@/pages/backoffice/Ledger/LedgerList/actions';
import { Action } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* updateRequest({ payload }: Action<actions.IUpdateRequest>) {
  try {
    yield put(setLedgerListLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      request_id: payload.requestId,
    } as DocPatchInputSchema);

    yield put(getLedgerList(null));

    yield put(
      showNotification({
        variant: 'success',
        title: 'notification.success.text.request_update',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setLedgerListLoading({ loading: false }));
  }
}

export default [takeLatest(actions.updateRequest, updateRequest)];
