import { POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { showNotification } from 'app/actions';
import { JmsEmailSchema } from '@/generated';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { ISendForApprovalItemModal } from '@/pages/backoffice/Modals/types';
import { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* sendDocumentForApproval({ payload }: Action<ISendForApprovalItemModal>) {
  try {
    yield put(toggleModalLoading());

    const { invoiceCode, documentId } = payload;

    const boatId: number = yield select(getCurrentBoatId);

    yield call(POST, '/integrations/jms/send_notification', {
      boat_id: boatId,
      document_id: documentId,
      category_code: invoiceCode.toString(),
    } as JmsEmailSchema);

    yield all([
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success',
        })
      ),
    ]);
  } catch (e) {
    yield all([put(toggleModalLoading()), call(responseError, e)]);
  }
}

export default [takeLatest(actions.sendDocumentForApproval, sendDocumentForApproval)];
