import { getData, setData, setLoading } from '@core-ui/reducers_handlers';
import { resetState } from '@core-ui/reducers_handlers/actions';
import { DocumentSearchResult } from '@/generated';

export const reducerName = 'ledger.document.data';

export const resetLedgerDocument = resetState(reducerName);

export const getLedgerDocument = getData(reducerName);
export const setLedgerDocument = setData<DocumentSearchResult>(reducerName);

export const setLedgerDocumentLoading = setLoading(reducerName);
