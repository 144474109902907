import { getCurrentBoatConfig, getCurrentBoatId } from 'app/selectors';
import { getClientCostArticlesDictOptions, getCurrencyCodeById, getRequestsDictOptions } from 'dictionary/selector';
import {
  getMasterDocumentRootLoading,
  getMasterDocumentRootValue,
} from 'pages/backoffice/Modals/MasterDocumentModal/MasterDocumentModalRoot/selector';
import { getWalletsOptions } from 'pages/backoffice/Wallets/WalletsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [
    getMasterDocumentRootValue,
    getCurrentBoatId,
    getClientCostArticlesDictOptions,
    getWalletsOptions,
    getRequestsDictOptions,
    getMasterDocumentRootLoading,
    getCurrencyCodeById,
    getCurrentBoatConfig,
  ],
  (
    document,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,
    currencyCodeGetter,
    boatConfig
  ) => ({
    document,
    boatId,
    clientCostArticlesOptions,
    walletOptions,
    requestOptions,
    loading,

    paymentInfoCurrencyCode: currencyCodeGetter(document?.payment_info?.currency_id ?? boatConfig?.default_currency_id),
    paymentInfoCurrencyId: document?.payment_info?.currency_id ?? boatConfig?.default_currency_id,
    paymentInfoDate: document?.payment_date ?? null,
    paymentInfoAmountInitial: document?.payment_info?.total_price ? String(document?.payment_info?.total_price) : '',
    paymentInfoRate: document?.payment_info?.rate ?? null,
  })
);
