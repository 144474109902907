import { getParams } from '@core-ui/redux-router';
import { GET, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { ISagaContext } from '@/app/types/common';
import { showNotification } from 'app/actions';
import { DocLinesInputSchema, DocumentLinesResponse } from '@/generated';
import { LEDGER_PATHS } from '@/pages/backoffice/Ledger/routes';
import { LedgerDocumentPathParams } from '@/pages/backoffice/Ledger/types';
import { Action } from 'redux-actions';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { IUpdateLineErpCategory } from './actions';

function* getLedgerDocumentLines() {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const { documentId } = getParams<LedgerDocumentPathParams>(history.location.pathname, LEDGER_PATHS);

    const lines: DocumentLinesResponse[] = yield call(GET, `/document/${documentId}/lines`);

    if (!lines || lines.length === 0) {
      throw new Error('No lines found');
    }

    yield put(
      actions.setLedgerDocumentLines({
        value: lines,
        hasData: true,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setLedgerDocumentLines({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* updateLineErpCategory({ payload }: Action<IUpdateLineErpCategory>) {
  try {
    yield put(actions.setLedgerLinesListLoading({ loading: true }));

    const result: DocumentLinesResponse[] = yield call(PATCH, `/document/${payload.documentId}/lines`, {
      lines: [
        {
          id: payload.lineId,
          xero_code: payload.categoryId,
        },
      ],
    } as DocLinesInputSchema);

    yield put(
      actions.setLedgerDocumentLines({
        value: result,
        hasData: true,
        isAllDataReceived: true,
      })
    );
    yield put(
      showNotification({
        variant: 'success',
        title: 'notification.success.text.erp_update',
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        title: 'notification.error.text.erp_update',
      })
    );
  } finally {
    yield put(actions.setLedgerLinesListLoading({ loading: false }));
  }
}

export default [
  takeLatest(actions.getLedgerDocumentLines, getLedgerDocumentLines),
  takeLatest(actions.updateLineErpCategory, updateLineErpCategory),
];
