export const MODAL_OUTER_MARGIN = 20;

export const MASTER_DOCUMENT_HEADER_HEIGHT = 78;
export const MASTER_DOCUMENT_HEADER_TITLE_SKELETON_WIDTH = 400;
export const MASTER_DOCUMENT_HEADER_APPROVE_BUTTONS_SKELETON_WIDTH = 240;
export const MASTER_DOCUMENT_HEADER_SKELETON_HEIGHT = 36;

export const MASTER_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH = 750;
export const MASTER_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH = 340;
export const MASTER_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH = 960;

export const MASTER_DOCUMENT_CONTENT_TABS_HEIGHT = 40;

export const MASTER_DOCUMENT_INFO_TAB_DIVIDER_WIDTH = 678;
export const MASTER_DOCUMENT_INFO_TAB_SELECTOR_CONTAINER_WIDTH = '75%';
export const MASTER_DOCUMENT_INFO_TAB_SELECTORS_WIDTH = 448;
export const MASTER_DOCUMENT_INFO_TAB_VALUE_FIELD_WIDTH = 450;
export const MASTER_DOCUMENT_INFO_TAB_LABEL_WIDTH = 160;

export const MASTER_DOCUMENT_LINE_TAB_NAME_COLUMN_WIDTH = 300;
export const MASTER_DOCUMENT_PAYMENT_DATE_TOTAL_AMOUNT_WIDTH = 382;

export enum MASTER_DOCUMENT_TAB_ENUM {
  DOCUMENT = 'document',
  LINES = 'lines',
}
