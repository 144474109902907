import { useQuery } from '@core-ui/redux-router';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import SvgIcon from '@mui/material/SvgIcon';
import { ROUTES } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { LEDGER_DOCUMENT_TAB_ENUM } from '@/pages/backoffice/Ledger/consts';
import { LedgerDocumentQueryParams } from '@/pages/backoffice/Ledger/types';
import { FileText, Info } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import selector from './selector';

const LedgerDocumentTabs = () => {
  const { item, boatId } = useAppSelector(selector);
  const { tab, previous_section, previous_id } = useQuery<LedgerDocumentQueryParams>();

  const tabs: ITab[] = [
    {
      value: LEDGER_DOCUMENT_TAB_ENUM.LINES,
      label: <FormattedMessage id="label.lines" />,
      dataTestId: 'ledger-document-lines-tab',
      to: buildURL<LedgerDocumentQueryParams>(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}/${item?.id}`, {
        tab: LEDGER_DOCUMENT_TAB_ENUM.LINES,
        previous_section,
        previous_id,
      }),
      icon: (
        <SvgIcon>
          <FileText />
        </SvgIcon>
      ),
    },
    {
      value: LEDGER_DOCUMENT_TAB_ENUM.INFORMATION,
      label: <FormattedMessage id="label.information" />,
      dataTestId: 'ledger-document-info-tab',
      to: buildURL<LedgerDocumentQueryParams>(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}/${item?.id}`, {
        tab: LEDGER_DOCUMENT_TAB_ENUM.INFORMATION,
        previous_section,
        previous_id,
      }),
      icon: (
        <SvgIcon>
          <Info />
        </SvgIcon>
      ),
    },
  ];

  return <Tabs size="small" tabs={tabs} active={tab} />;
};

export default LedgerDocumentTabs;
