import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { DocumentSearchResult } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from '@/pages/backoffice/Modals/command';
import { Image } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  item: DocumentSearchResult;
}

const LedgerListRowMoreButton = (props: IProps) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleOpenSourceFileModal = () => {
    if (item.files) {
      const files = item.files.map((file) => file.link);
      dispatch(
        openBackofficeModal(
          new ImageCarouselModalData(files, item.id, item.status, item.uploaded_by_id, item.created_at)
        )
      );
    }
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.open_source_file" />,
      dataTestId: 'open-source-file-option',
      icon: <Image />,
      onClick: handleOpenSourceFileModal,
      visible: true,
    },
  ];

  return <MoreButton options={options} isRounded buttonVariant="filled" dataTestId="ledger-table-row-more-button" />;
};

export default LedgerListRowMoreButton;
