import { PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from 'app/actions';
import { ApproveStatusEnum, RequestDetailOutputV2Schema, UpdateApprove } from '@/generated';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { Action } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getSingleRequest, setSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { getViewPanelStateValue } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { updateItemInRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { setCreateEditRequestFromViewPanel } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';

function* declineRequest({ payload }: Action<actions.IDeclineRequestModalData>) {
  try {
    const { requestId } = payload;

    yield put(toggleModalLoading());

    yield call(PUT, '/approves', [
      {
        request_id: requestId,
        status: ApproveStatusEnum.DECLINED,
      },
    ] as UpdateApprove[]);

    yield all([
      put(getSingleRequest({ id: requestId })),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.request_decline',
        })
      ),
    ]);

    yield take(setSingleRequest);
    const updatedRequest: RequestDetailOutputV2Schema = yield select(getViewPanelStateValue);

    yield all([
      put(updateItemInRequestList({ item: updatedRequest })),
      put(setCreateEditRequestFromViewPanel({ request: updatedRequest })),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(closeBackofficeModal());
  }
}

export default [takeLatest(actions.declineRequest, declineRequest)];
