import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useIntl } from 'components/Intl';

const LedgerDocumentHeaderSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      gap={6}
      paddingY={6}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Grid container direction="column" gap={2} marginBottom="12px">
        <Skeleton variant="rounded" height={26} width={124} />

        <Grid item container justifyContent="space-between" alignItems="center" gap={2}>
          <Skeleton variant="rounded" height={36} width={290} />

          <Grid item container gap={2} width="auto">
            <Skeleton variant="rounded" height={36} width={95} sx={{ bgcolor: COLOURS.WHITE }} />
            <Skeleton variant="rounded" height={36} width={89} sx={{ bgcolor: COLOURS.WHITE }} />
            <Skeleton variant="circular" height={36} width={36} sx={{ bgcolor: COLOURS.WHITE }} />
          </Grid>
        </Grid>

        <Skeleton variant="rounded" height={26} width={150} />
      </Grid>

      <Grid item container justifyContent="space-between">
        <Skeleton variant="rounded" height={28} width={190} />
        <Skeleton variant="rounded" height={26} width={124} />
      </Grid>
    </Grid>
  );
};

export default LedgerDocumentHeaderSkeleton;
