import State from '@/app/types/state';
import { createSelector } from 'reselect';
import { DeclineRequestModalData } from 'pages/backoffice/Modals/command';

const getModalsData = (state: State) => state.backoffice.modals.data as DeclineRequestModalData;
const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  requestId: data?.requestId,
  loading,
}));
