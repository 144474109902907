import { COLOURS } from '@core-ui/styles';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import Grid from '@mui/material/Grid';
import { useIntl } from 'components/Intl';

const styles: SxProps<Theme> = {
  background: COLOURS.Coconut.Solid[100],
  transform: 'none',
};

const ApproveDocumentTableModalSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      item
      container
      gap={3}
      wrap="nowrap"
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton width={200} height={70} sx={styles} />
      <Skeleton width={200} height={70} sx={styles} />
    </Grid>
  );
};

export default ApproveDocumentTableModalSkeleton;
