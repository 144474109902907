import { GET, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from 'app/actions';
import { DocLinesInputSchema, DocumentLinesResponse } from '@/generated';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getMasterDocumentModalDocumentLines({ payload }: Action<actions.IGetMasterDocumentLine>) {
  try {
    const { documentId } = payload;

    const lines: DocumentLinesResponse[] = yield call(GET, `/document/${documentId}/lines`);

    if (!lines || lines.length === 0) {
      throw new Error('No lines found');
    }

    yield put(
      actions.setMasterDocumentModalDocumentLines({
        value: lines,
        hasData: true,
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield all([
      put(
        actions.setMasterDocumentModalDocumentLines({
          error: e as Error,
          hasData: false,
          isAllDataReceived: false,
        })
      ),
      call(responseError, e),
    ]);
  }
}

function* updateLineErpCategory({ payload }: Action<actions.IUpdateLineErpCategory>) {
  try {
    yield put(actions.setMasterDocumentModalLinesListLoading({ loading: true }));

    const result: DocumentLinesResponse[] = yield call(PATCH, `/document/${payload.documentId}/lines`, {
      lines: [
        {
          id: payload.lineId,
          xero_code: payload.categoryId,
        },
      ],
    } as DocLinesInputSchema);

    yield all([
      put(
        actions.setMasterDocumentModalDocumentLines({
          value: result,
          hasData: true,
          isAllDataReceived: true,
        })
      ),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.erp_update',
        })
      ),
    ]);
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        title: 'notification.error.text.erp_update',
      })
    );
  } finally {
    yield put(actions.setMasterDocumentModalLinesListLoading({ loading: false }));
  }
}

export default [
  takeLatest(actions.getMasterDocumentModalDocumentLines, getMasterDocumentModalDocumentLines),
  takeLatest(actions.updateLineErpCategory, updateLineErpCategory),
];
