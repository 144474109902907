import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FileText, X } from '@phosphor-icons/react';
import ApprovalFlowStatus from 'components/ApprovalFlowStatus';
import { useIntl } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import {
  MASTER_DOCUMENT_HEADER_APPROVE_BUTTONS_SKELETON_WIDTH,
  MASTER_DOCUMENT_HEADER_SKELETON_HEIGHT,
  MASTER_DOCUMENT_HEADER_TITLE_SKELETON_WIDTH,
} from '../consts';
import { ValidationButtons } from './components';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  onClose: () => void;
}

const MasterDocumentModalHeader = (props: IProps) => {
  const { onClose } = props;
  const { document, vendorName, loading, budgetApproveFlowActive } = useAppSelector(selector);

  const styles = useStyles();
  const intl = useIntl();

  return (
    <Grid item container sx={styles.container}>
      <Grid item container sx={styles.titleContainer}>
        {loading ? (
          <Skeleton
            variant="rounded"
            width={MASTER_DOCUMENT_HEADER_TITLE_SKELETON_WIDTH}
            height={MASTER_DOCUMENT_HEADER_SKELETON_HEIGHT}
            role="progressbar"
            aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
          />
        ) : (
          <Grid item container gap={4} alignItems="center" width="auto" wrap="nowrap">
            <SvgIcon fontSize="large">
              <FileText />
            </SvgIcon>

            <Typography variant="h3" sx={styles.title}>
              {vendorName}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item container gap={3} width="auto" wrap="nowrap">
        {loading ? (
          <Skeleton
            variant="rounded"
            width={MASTER_DOCUMENT_HEADER_APPROVE_BUTTONS_SKELETON_WIDTH}
            height={MASTER_DOCUMENT_HEADER_SKELETON_HEIGHT}
            role="progressbar"
            aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
          />
        ) : (
          <>
            <ApprovalFlowStatus item={document} />
            {budgetApproveFlowActive && <ValidationButtons document={document} />}
          </>
        )}

        <Button
          onClick={onClose}
          size="small"
          variant="filled"
          color="black"
          icon={<X />}
          aria-label={intl.formatMessage({ id: 'aria.label.close_modal' })}
        />
      </Grid>
    </Grid>
  );
};

export default MasterDocumentModalHeader;
