import { useAppSelector } from '@/hooks/useAppSelector';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  ApproveDocumentModal,
  CreateEditContractModal,
  CreateEditWalletModal,
  DeclineDocumentModal,
  DeleteContractModal,
  DeleteInboxDocumentModal,
  DeleteSingleContractTransactionModal,
  DeleteWalletModal,
  DeleteWalletTransactionModal,
  ImageCarouselModal,
  MasterDocumentModal,
  SendForApprovalModal,
  SendToErpModal,
} from '.';
import { closeBackofficeModal } from './actions';
import {
  ApproveDocumentModalData,
  CreateEditContractModalData,
  CreateEditWalletModalData,
  DeclineDocumentModalData,
  DeclineRequestModalData,
  DeleteContractModalData,
  DeleteInboxDocumentData,
  DeleteSingleContractTransactionModalData,
  DeleteWalletModalData,
  DeleteWalletTransactionModalData,
  ImageCarouselModalData,
  MasterDocumentModalData,
  SendForApprovalModalData,
  SendToXeroItemModalData,
} from './command';
import selector from './selector';
import DeclineRequestModal from 'pages/backoffice/Modals/DeclineRequestModal';

const BackofficeModals = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(selector);

  const handleClose = useCallback(() => dispatch(closeBackofficeModal()), [dispatch]);

  return (
    <>
      <ApproveDocumentModal
        opened={state.opened && state.data instanceof ApproveDocumentModalData}
        onClose={handleClose}
      />

      <CreateEditContractModal
        opened={state.opened && state.data instanceof CreateEditContractModalData}
        onClose={handleClose}
      />

      <CreateEditWalletModal
        opened={state.opened && state.data instanceof CreateEditWalletModalData}
        onClose={handleClose}
      />

      <DeclineDocumentModal
        opened={state.opened && state.data instanceof DeclineDocumentModalData}
        onClose={handleClose}
      />

      <DeleteContractModal
        opened={state.opened && state.data instanceof DeleteContractModalData}
        onClose={handleClose}
      />

      <DeleteInboxDocumentModal
        opened={state.opened && state.data instanceof DeleteInboxDocumentData}
        onClose={handleClose}
      />

      <DeleteSingleContractTransactionModal
        opened={state.opened && state.data instanceof DeleteSingleContractTransactionModalData}
        onClose={handleClose}
      />

      <DeleteWalletModal opened={state.opened && state.data instanceof DeleteWalletModalData} onClose={handleClose} />

      <DeleteWalletTransactionModal
        opened={state.opened && state.data instanceof DeleteWalletTransactionModalData}
        onClose={handleClose}
      />

      <ImageCarouselModal opened={state.opened && state.data instanceof ImageCarouselModalData} onClose={handleClose} />

      <SendForApprovalModal
        opened={state.opened && state.data instanceof SendForApprovalModalData}
        onClose={handleClose}
      />

      <SendToErpModal opened={state.opened && state.data instanceof SendToXeroItemModalData} onClose={handleClose} />

      <MasterDocumentModal
        opened={state.opened && state.data instanceof MasterDocumentModalData}
        onClose={handleClose}
      />

      <DeclineRequestModal
        opened={state.opened && state.data instanceof DeclineRequestModalData}
        onClose={handleClose}
      />
    </>
  );
};

export default BackofficeModals;
