import {
  ApproveDocumentModalData,
  CreateEditContractModalData,
  CreateEditWalletModalData,
  DeclineDocumentModalData,
  DeleteContractModalData,
  DeleteInboxDocumentData,
  DeleteSingleContractTransactionModalData,
  DeleteWalletModalData,
  DeleteWalletTransactionModalData,
  ImageCarouselModalData,
  MasterDocumentModalData,
  SendForApprovalModalData,
  SendToXeroItemModalData,
} from '@/pages/backoffice/Modals/command';
import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import DeclineRequestModalData from './command/DeclineRequestModalData';

export type IModalsData =
  | ApproveDocumentModalData
  | CreateEditContractModalData
  | CreateEditWalletModalData
  | DeclineDocumentModalData
  | DeleteContractModalData
  | DeleteInboxDocumentData
  | DeleteSingleContractTransactionModalData
  | DeleteWalletModalData
  | DeleteWalletTransactionModalData
  | ImageCarouselModalData
  | SendForApprovalModalData
  | SendToXeroItemModalData
  | MasterDocumentModalData
  | DeclineRequestModalData;

export interface IModalsState {
  data: Nullable<IModalsData>;
  opened: boolean;
  loading: boolean;
}

const defaultState = Immutable<IModalsState>({
  data: null,
  opened: false,
  loading: false,
});

export default handleActions<typeof defaultState>(
  {
    [actions.toggleModalLoading.toString()]: (state) => {
      return state.set('loading', !state.loading);
    },
    // side effect for resetting loading state on modal close
    [actions.closeBackofficeModal.toString()]: (state) => {
      return state.set('loading', false);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
